import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-center py-3" }
const _hoisted_2 = { class: "btn-toolbar" }
const _hoisted_3 = {
  key: 0,
  class: ""
}
const _hoisted_4 = { class: "card col-12 col-lg-6" }
const _hoisted_5 = { class: "card-header" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "my-2" }
const _hoisted_8 = { class: "card-text" }
const _hoisted_9 = { class: "my-2" }
const _hoisted_10 = { class: "card-text" }
const _hoisted_11 = { class: "my-2" }
const _hoisted_12 = { class: "card-text" }
const _hoisted_13 = { class: "my-2" }
const _hoisted_14 = { class: "card-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HourglassLoader = _resolveComponent("HourglassLoader")!

  return (_openBlock(), _createBlock(_component_HourglassLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      _createElementVNode("section", null, [
        _createElementVNode("div", _hoisted_1, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "d-block" }, [
            _createElementVNode("h2", { class: "h4" }, "Coin Type Details")
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-sm btn-outline-primary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.RefreshData && _ctx.RefreshData(...args)))
            }, "Reload")
          ])
        ]),
        (_ctx.data)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.data.name), 1),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _cache[2] || (_cache[2] = _createElementVNode("h5", { class: "card-title" }, "Name", -1)),
                    _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.data.name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _cache[3] || (_cache[3] = _createElementVNode("h5", { class: "card-title" }, "Rate", -1)),
                    _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.data.usd_rate), 1)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _cache[4] || (_cache[4] = _createElementVNode("h5", { class: "card-title" }, "Date", -1)),
                    _createElementVNode("p", _hoisted_12, _toDisplayString(new Date(_ctx.data.created_at).toLocaleString()), 1)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _cache[5] || (_cache[5] = _createElementVNode("h5", { class: "card-title" }, "ID", -1)),
                    _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.data.id), 1)
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}